<template>
  <div>
    <div class="ad-block"
         v-for="item in adList"
         :key="item.adId">
      <div class="ad-item"
           v-if="item.status"
           v-html="item.con"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    getAdInfoUrl: {
      type: String,
      defailt: ''
    }
  },
  data () {
    return {
      adList: []
    }
  },
  created () {
    this.getAdInfo()
  },
  methods: {
    async getAdInfo () {
      const res = await axios.get(this.getAdInfoUrl, {
        params: {
          positions: '540'
        }
      }).then(res => {
        this.adList = res?.data
      })
    }
  }
}
</script>
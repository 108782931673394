import Vue from 'vue';
import Router from 'vue-router';
import axios from 'axios';
import Vuex from 'vuex';
import {
  Button,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Input,
  Select,
  Option,
  Autocomplete,
  Upload,
  RadioGroup,
  Radio,
  Tag,
  Menu,
  MenuItem,
  Submenu,
  Collapse,
  CollapseItem,
  Tree,
  Message,
  Card,
  Switch,
  MessageBox,
  Popover,
  Loading,
  Pagination,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Table,
  TableColumn
} from 'element-ui';

import 'style/themes/element_orange/index.css';

Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Input);
Vue.use(Select);
Vue.use(Autocomplete);
Vue.use(Option);
Vue.use(Upload);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Tag);
Vue.use(Menu);
Vue.use(Switch);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Tree);
Vue.use(Card);
Vue.use(Popover);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Pagination);
Vue.use(Loading.directive);
Vue.use(Router);
Vue.use(Vuex);

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
class CreateVueExample {
  constructor(data) {
    this.initExample(data);
  }
  initExample(data) {
    const vm = new Vue({
      ...data,
    });
  }
}

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

export { Router, CreateVueExample as Vue, axios };

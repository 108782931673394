var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"swiper-container live-swiper-container"},[_c('div',{staticClass:"live-list-bar swiper-wrapper"},_vm._l((_vm.liveList),function(item,index){return _c('div',{key:index,class:[
          'live-item',
          'swiper-slide',
          {
            wait: item.liveStatus === _vm.statusList.wait,
            live: item.liveStatus === _vm.statusList.live,
            end: item.liveStatus === _vm.statusList.end,
            record: item.liveStatus === _vm.statusList.record,
          } ]},[_c('div',{staticClass:"live-item-logo",on:{"click":function($event){return _vm.changeLiveItem(item)}}},[_c('img',{attrs:{"src":item.image}}),_vm._v(" "),(item.liveStatus !== _vm.statusList.wait)?_c('span',{staticClass:"live-status"},[_vm._v(_vm._s(_vm._f("formatStatus")(item.liveStatus,_vm.that)))]):_vm._e(),_vm._v(" "),(_vm.isLiving(item))?_c('span',{staticClass:"living-icon"}):_vm._e()])])}),0)]),_vm._v(" "),_c('live-room',{staticClass:"mobile-hide",attrs:{"src":_vm.liveItem && _vm.liveItem.url}}),_vm._v(" "),_c('div',{staticClass:"enter-liveroom mobile-show"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.liveItem.title || ''))]),_vm._v(" "),_c('img',{attrs:{"src":_vm.enterLiveRoomPicUrl,"width":"100%"},on:{"click":_vm.openLiveRoom}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
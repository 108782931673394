import $ from 'jquery';
import Cookies from 'js-cookie';
// 无邮箱用户登录跳转
export function supplyEmailWhenLogin() {
  // const pathInfo = location.search
  document.addEventListener('DOMContentLoaded', function() {
    if (Cookies.get('need_supply') == 'email') {
      // if (/^\?need_supply=email/.test(pathInfo)) {
      _setEmailPop();
    }
  });
}
// set email pop
function _setEmailPop() {
  $('#inputEmailDialog').modal({ show: true, backdrop: 'static' });
  $('#inputEmailDialog').on('hidden.bs.modal', function(e) {
    $('#unCheckEmailAlert').show();
    $('#unCheckEmailAlert .js-open-email-box').on('click', function() {
      $('#inputEmailDialog').modal({ show: true, backdrop: 'static' });
    });
  });
  $(document).on('click', '.js-set-email', function() {
    var eRg = /^[A-Za-z0-9-_\u4e00-\u9fa5\.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/i;
    var email = $('#useremail').val();
    if (eRg.test(email)) {
      _setEmailRequest(email);
    } else {
      $('#inputEmailDialog .err-msg').show();
      $('#useremail').focus(function() {
        $('#inputEmailDialog .err-msg').hide();
      });
    }
  });
}
// 存储用户email接口
function _setEmailRequest(email) {
  $.get({
    url: `/sign_in_recallback?email=${email}`,
    success: res => {
      // console.log("请求成功",res);
      if (res.msg == 'success') {
        Cookies.set('need_supply', '');
        Cookies.remove('need_supply');
        window.location.reload();
      } else {
        alert(res.msg);
      }
      // $('#inputEmailDialog').modal('hide');
      // Cookies.set("need_supply","");
      // $('#checkEmailAlert').show();
      // $('#unCheckEmailAlert').hide();
    },
    error: err => {
      console.err('请求失败', err);
    },
  });
}

// admin页面显示siedbar
export function setAdminSideBar() {
  const pathName = location.pathname;
  function setPaddingLeft(isClick = true) {
    const isFold = $('.layout-page.page-with-contextual-sidebar').hasClass(
      'page-with-icon-sidebar',
    );
    $('.layout-page.page-with-contextual-sidebar').css({
      'padding-left': (isClick ? isFold : !isFold) ? '50px' : '220px',
      transition: 'padding-left 0.3s',
    });
  }

  document.addEventListener('DOMContentLoaded', function() {
    if (/^\/admin/.test(pathName)) {
      document.querySelector('.breadcrumbs').style.display = 'block';
      document.querySelector('.nav-sidebar').style.display = 'block';
      const footer = document.querySelector('.bottom-pub-footer');
      if (footer) {
        footer.style.display = 'none';
      }
      setPaddingLeft();
      document.querySelector('.js-toggle-sidebar').addEventListener('click', function() {
        setPaddingLeft(false);
      });
    }
  });
}
/**
 * 关键操作校验
 */
export function mainHandleAgent(cb) {
  // window.csdn.userLogin &&
  //   window.csdn.userLogin.loadAjax(function(res) {
      cb && cb();
    // });
}

// 百度统计
export function baiduStatistics() {
  var _hmt = _hmt || [];
  (function() {
    var hm = document.createElement('script');
    var host = window.location.host;
    if(host.indexOf('gitcode.net')>=0){
      hm.src = "https://hm.baidu.com/hm.js?8eefd6b163dcb3f5762af6b0825e2dd1";
    }else{
      hm.src = 'https://hm.baidu.com/hm.js?6bcd52f51e9b3dce32bec4a3997715ac';
    }
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(hm, s);
  })();
}

<template>
  <div class="open_in_bowser">
    <p>邀请链接暂不支持微信内打开，请复制邀请链接并在浏览器中打开。</p>
    <span class="copy_link" @click="copyLink">复制链接</span>
    <span class="copy_success">复制成功</span>
  </div>
</template>
<script>
import $ from 'jquery';
export default {
  props: ['url'],
  data() {
    return {};
  },
  methods: {
    copyLink() {
      const link = this.url;
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.value = link + '';
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
      }
      document.body.removeChild(input);
      $('.copy_success').fadeIn();
      setTimeout(() => {
        $('.copy_success').fadeOut();
      }, 1500);
    },
  },
};
</script>
<style lang="less" scoped>
.open_in_bowser {
  padding: 0 32px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 17px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  .copy_link {
    position: relative;
    display: block;
    width: 184px;
    margin-left: auto;
    margin-right: auto;
    padding: 8px 24px;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 17px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    line-height: 1.41176471;
    border-radius: 4px;
    background: #06ae56;
    overflow: hidden;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-top: 200px;
  }
  .copy_success {
    position: fixed;
    display: none;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 4px;
    padding: 4px 18px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

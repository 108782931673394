import { Vue } from '~/create_vue_example';
import OpenInBowser from './open-in-bowser.vue';
import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  const wechatSelector = document.getElementById('open_in_bowser');
  if (!wechatSelector) return;
  const url = $(wechatSelector).data('url');
  new Vue({
    el: '#open_in_bowser',
    components: { OpenInBowser },
    data() {
      return {
        url,
      };
    },
    template: '<open-in-bowser :url="url" />',
  });
});

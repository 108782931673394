export default {
  state: {
    courseInfo: {}, // 课程基本信息
    courseVersionInfo: {}, // 课程基本信息
    uploadsPath: '', //
    courseImgList: [],
    bannerUrl: '',
    bannerLink: '',
    currentVersionId: '',
    courseChapters: [],
    defaultImg: 'https://picsum.photos/600/360', // 默认封面
  },
  mutations: {
    changeCurrentVersionId(state, val) {
      state.currentVersionId = val;
    },
    setCourseInfo(state, val) {
      state.courseInfo = val;
    },
    setCourseChapters(state, val) {
      state.courseChapters = val;
    },
    setCourseVersionInfo(state, val) {
      state.courseVersionInfo = val;
    },
    setUploadsPath(state, val) {
      state.uploadsPath = val;
    },
    setCourseListBanner(state, val) {
      state.bannerUrl = val.bannerUrl;
      state.bannerLink = val.bannerLink;
    },
  },
  actions: {},
};

import { Vue } from '~/create_vue_example';
import router from './router';
import storeConfig from './store';
import Vuex from 'vuex';

document.addEventListener('DOMContentLoaded', () => {
  const courseSelector = document.getElementById('opensource_festival');
  if (!courseSelector) return;
  const store = new Vuex.Store(storeConfig);
  new Vue({
    el: '#opensource_festival',
    router,
    store,
    template: '<router-view />',
  });
});

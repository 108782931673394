import api from '../api';
export default {
  state: {
    defaultBannerUrl: 'https://picsum.photos/1440/300',
    bannerInfo: '',
    filter: {
      category: '',
      project_name: '',
      page: 1,
    },
    typeName: '',
    subPath: '',
    projectCount: 0,
    loading: false,
    noMore: false,
    tabList: [],
    list: [],
    rules: [],
  },
  mutations: {
    changeSubpath(state, val) {
      state.subPath = val;
    },
    changeTypeName(state, val) {
      state.typeName = val;
    },
    changeCategory(state, val) {
      state.filter.category = val;
    },
    changeProjectName(state, val) {
      state.filter.project_name = val;
    },
    changePage(state, val) {
      state.filter.page = val;
    },
    changeProjectList(state, val) {
      state.list = val;
    },
    changeTabList(state, val) {
      state.tabList = val;
    },
    changeRules(state, val) {
      state.rules = val;
    },
    changeLoadingStatus(state, val) {
      state.loading = val;
    },
    changeBannerInfo(state, val) {
      state.bannerInfo = val;
    },
    changeNoMore(state, val) {
      state.noMore = val;
    },
    changeProjectCount(state, val) {
      state.projectCount = val;
    },
  },
  actions: {
    getProjectList(context) {
      context.commit('changeLoadingStatus', true);
      api.getProjectList(context.state.filter).then(({ data: res }) => {
        context.commit('changeLoadingStatus', false);
        if (res.status !== 200) return;
        if (!res.data.projects.length) {
          context.commit('changeNoMore', true);
        }
        const list = context.state.list;
        list.push(...res.data.projects);
        context.commit('changeProjectList', list);
        context.commit('changeProjectCount', res.data.project_count);
      });
    },
    getTypeOfProject(context) {
      const subPath = context.state.subPath;
      api.getTypeOfProject().then(({ data: res }) => {
        if (res.status !== 200) return;
        context.commit('changeTabList', res.data.categories);
        context.commit('changeRules', res.data.explain && res.data.explain[subPath]);
        context.commit('changeBannerInfo', res.data.banner[subPath]);
      });
    },
    resetPageAndList(context) {
      context.commit('changePage', 1);
      context.commit('changeNoMore', false);
      context.commit('changeProjectList', []);
    },
  },
};

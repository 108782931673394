<template>
  <div class="live-room">
    <iframe :src="src"
            allowfullscreen="true"
            class="open-source-live"
            frameborder="0"></iframe>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  created () {
  },
  data () {
    return {

    }
  },
  methods: {
  },
}
</script>

<style scoped>
.live-room {
  padding: 20px 0;
  background: #f5f6f7;
  width: 100%;
  overflow: hidden;
}
</style>
import Vue from 'vue';
import LiveList from '~/pages/explore/lives/components/live-list.vue';
import enterLiveRoomPicUrl from '~/pages/explore/lives/assets/enter_liveroom.png';

function CreateLiveList(selector) {
  const baseUrl = location.host;

  const vm = new Vue({
    el: selector,
    render(createElement) {
      return createElement(LiveList, {
        props: {
          getLiveListUrl: '/lives/lives',
          getLiveStatusUrl: '/lives/live_status',
          enterLiveRoomPicUrl: enterLiveRoomPicUrl,
        },
      });
    },
  });
}

export default CreateLiveList;

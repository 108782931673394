import { Vue } from '~/create_vue_example';
import Index from './index.vue';
import $ from 'jquery';
document.addEventListener('DOMContentLoaded', () => {
  const addTagsSelector = document.getElementById('tag-list');
  const tags = $('#tag-list').data('tags');
  const path = $('#tag-list').data('path');
  const listonly = $('#tag-list').data('listonly');
  if (!addTagsSelector) return;
  new Vue({
    el: '#tag-list',
    components: { Index },
    data() {
      return {
        tags,
        path,
        listonly,
      };
    },
    template: '<Index :tags.sync="tags" :path="path" :listonly="listonly" />',
  });
});

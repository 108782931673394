import { Vue } from '~/create_vue_example';
import router from './router';
import ZenMode from '~/zen_mode';
import storeConfig from './store';
import $ from 'jquery';
import Vuex from 'vuex';

document.addEventListener('DOMContentLoaded', () => {
  const courseSelector = document.getElementById('course-app');
  if (!courseSelector) return;
  const store = new Vuex.Store(storeConfig);
  const bannerUrl = $(courseSelector).data('banner-url');
  const bannerLink = $(courseSelector).data('banner-link');
  store.commit('setCourseListBanner', { bannerUrl, bannerLink });
  new Vue({
    el: '#course-app',
    router,
    store,
    template: '<router-view />',
  });
  new ZenMode();
});

<template>
  <div class="tag-component">
    <el-input
      v-if="canEdit"
      ref="tags_input"
      placeholder="多个主题请用逗号分隔"
      @blur="addNewTags"
      @keydown.enter.native="addNewTags"
      v-model="newTags"
      v-show="showInput"
      :autofocus="true"
      size="small"
    ></el-input>

    <div class="tag_list" v-if="tagList.length">
      <el-tag
        type="success"
        v-for="(item, index) in tagList"
        :key="index"
        size="small"
        :closable="canEdit"
        :disable-transitions="false"
        @close="deleteTag(item, index)"
        @click="linktoTag(item)"
        data-report-view='{"spm":"3001.5878"}'
        data-report-click='{"spm":"3001.5878"}'
        data-report-query='spm=3001.5878'
        >{{ item }}</el-tag
      >
      <gl-icon
        v-if="canEdit"
        name="settings"
        :size="16"
        @click="showInput = !showInput"
        class="tags_setting"
      />
    </div>
    <div v-else-if="canEdit" v-show="!showInput" class="add_tags" @click="showInputComponent">
      点击添加主题
    </div>
  </div>
</template>
<script>
import { axios } from '~/create_vue_example';
import { GlIcon } from '@gitlab/ui';
export default {
  props: ['tags', 'path', 'listonly'],
  components: { GlIcon },
  data() {
    return {
      showInput: false,
      tagList: '',
      newTags: '',
    };
  },
  computed: {
    canEdit() {
      return this.listonly === undefined;
    },
  },
  created() {
    const tags = this.tags + '';
    this.tagList = tags ? tags.split(',').map(item => item.trim()) : [];
  },
  watch: {
    showInput(val) {
      this.$nextTick(() => {
        if (val) {
          this.$refs.tags_input.focus();
        }
      });
    },
  },
  methods: {
    showInputComponent() {
      this.showInput = true;
      this.$nextTick(() => {
        this.$refs.tags_input.focus();
      });
    },
    addNewTags() {
      const newTagStr = (this.newTags + '').trim();
      this.showInput = false;
      if (!newTagStr) return;
      this.tagList = Array.from(new Set(this.tagList.concat(newTagStr.split(',')))).filter(
        item => item !== '',
      );
      // this.showInput = this.tagList.length;
      this.saveTags();
      this.newTags = '';
    },
    deleteTag(tag, index) {
      this.tagList.splice(index, 1);
      this.saveTags();
    },
    linktoTag(tag) {
      location.href = `/explore/topics/${tag}`;
    },
    saveTags(list) {
      const form = new FormData();
      form.append('project[tag_list]', this.tagList.join(','));
      form.append('_method', 'patch');
      form.append('utf8', '✓');
      form.append('commit', '保存更改');
      axios.post(`/${this.path}`, form).then(res => {});
    },
  },
};
</script>
<style lang="less" scoped>
@color: #1068bf;
.tag-component {
  margin: 16px 0 -10px;
  .add_tags {
    border: 1px dashed @color;
    height: 32px;
    line-height: 32px;
    color: @color;
    font-size: 12px;
    padding: 0 15px;
    border-radius: 4px;
    font-weight: 700;
    letter-spacing: 0.05em;
    cursor: pointer;
  }
  /deep/.el-input {
    .el-input__inner:focus {
      border-color: @color;
    }
  }
  .tag_list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    /deep/.el-tag {
      margin: 8px 8px 0 0;
      color: @color;
      // border: 1px solid #d9ecff;
      border: 1px solid rgba(64, 158, 255, 0.6);
      background-color: #ecf5ff;
      cursor: pointer;
      .el-tag__close {
        color: @color;
        font-size: 16px;
        &:hover {
          background: @color;
          color: #fff;
        }
      }
    }
    .tags_setting {
      margin-top: 8px;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
</style>

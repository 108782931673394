import { Router } from '~/create_vue_example';

const courseRouter = new Router({
  base: '/open_index/stack',
  mode: 'history',
  routes: [
    {
      path: '*',
      component: resolve => require(['~/vue_shared/components/404.vue'], resolve),
    },
    {
      path: '/',
      component: resolve => require(['../views/App.vue'], resolve),
      children: [
        {
          path: '',
          name: 'StackIndex',
          component: resolve => require(['../views/index.vue'], resolve),
        },
        {
          path: '/stacks',
          name: 'Stacks',
          component: resolve => require(['../views/stack-list.vue'], resolve),
        },
        {
          path: '/cases',
          name: 'Cases',
          component: resolve => require(['../views/case-list.vue'], resolve),
        },
        {
          path: '/apps',
          name: 'Apps',
          component: resolve => require(['../views/app-list.vue'], resolve),
        },
      ],
    },
    {
      path: '/stackDetail/:id',
      name: 'StackDetail',
      component: resolve => require(['../views/stack-detail.vue'], resolve),
    },
    {
      path: '/appDetail/:id',
      name: 'AppDetail',
      component: resolve => require(['../views/app-detail.vue'], resolve),
    },
  ],
});

export default courseRouter;

import { Router } from '~/create_vue_example';

export default new Router({
  base: '/explore/open_source_fest/',
  mode: 'history',
  routes: [
    {
      path: '*',
      component: resolve => require(['~/vue_shared/components/404.vue'], resolve),
    },
    {
      path: '/',
      redirect: 'project',
    },
    {
      path: '/article',
      name: 'article',
      component: resolve => require(['../views/template.vue'], resolve),
    },
    {
      path: '/comment',
      name: 'comment',
      component: resolve => require(['../views/template.vue'], resolve),
    },
    {
      path: '/project',
      name: 'project',
      component: resolve => require(['../views/template.vue'], resolve),
    },
    {
      path: '/video',
      name: 'video',
      component: resolve => require(['../views/template.vue'], resolve),
    },
  ],
});

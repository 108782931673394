import { Vue } from '~/create_vue_example';
import router from './router';
import $ from 'jquery';

const path = location.pathname;
if (/\/open_index/.test(path)) {
  $('head').append('<meta name="referrer" content="no-referrer" />');
  $('head')
    .find('meta[name="report"]')
    .remove();

  $('head').append(`<meta name="report" content='{"spm":"1033.2253"}' />`);
}

document.addEventListener('DOMContentLoaded', () => {
  const courseSelector = document.getElementById('index-app');
  if (!courseSelector) return;
  new Vue({
    el: '#index-app',
    router,
    template: '<router-view />',
  });
});

import axios from 'axios'
import Vue from 'vue'

import AdBlock from '~/pages/dashboard/advertisement/components/ad-block.vue'


function CreateAdBlock (selector) {
  const vm = new Vue({
    el: selector,
    render (createElement) {
      return createElement(AdBlock, {
        props: {
          getAdInfoUrl: 'https://kunpeng.csdn.net/ad/list',
        },
      })
    },
  })
}

export default CreateAdBlock
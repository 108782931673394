import { Router } from '~/create_vue_example';

const courseRouter = new Router({
  base: '/courses/',
  mode: 'history',
  routes: [
    {
      path: '*',
      component: resolve => require(['~/vue_shared/components/404.vue'], resolve),
    },
    {
      path: '/',
      name: 'courselist',
      component: resolve => require(['../views/list.vue'], resolve),
    },
    {
      path: '/new',
      component: resolve => require(['../views/new.vue'], resolve),
    },
    {
      path: '/admin',
      component: resolve => require(['../views/admin.vue'], resolve),
    },
    {
      path: '/admin/:courseId',
      redirect: 'courseAdmin',
      component: resolve => require(['../views/admin-course.vue'], resolve),
      children: [
        {
          path: '/',
          name: 'courseAdmin',
          component: resolve => require(['../components/admin/course-profile.vue'], resolve),
        },
        {
          path: 'data/:type',
          name: 'courseData',
          component: resolve => require(['../components/admin/course-data.vue'], resolve),
        },
        {
          path: 'edit',
          name: 'editCourse',
          component: resolve => require(['../components/admin/course-edit.vue'], resolve),
        },
        {
          path: 'build',
          name: 'buildCourse',
          component: resolve => require(['../components/admin/course-build.vue'], resolve),
        },
        {
          path: 'files',
          name: 'courseFiles',
          component: resolve => require(['../components/admin/course-files.vue'], resolve),
        },
        {
          path: 'template',
          name: 'courseTemplate',
          component: resolve => require(['../components/admin/course-template.vue'], resolve),
        },
      ],
    },
    {
      path: '/detail/:courseId/:type',
      name: 'courseDetail',
      component: resolve => require(['../views/detail.vue'], resolve),
    },
  ],
});

export default courseRouter;

<template>
  <div>
    <div class="swiper-container live-swiper-container">
      <div class="live-list-bar swiper-wrapper">
        <div
          :class="[
            'live-item',
            'swiper-slide',
            {
              wait: item.liveStatus === statusList.wait,
              live: item.liveStatus === statusList.live,
              end: item.liveStatus === statusList.end,
              record: item.liveStatus === statusList.record,
            },
          ]"
          v-for="(item, index) in liveList"
          :key="index"
        >
          <div class="live-item-logo" @click="changeLiveItem(item)">
            <img :src="item.image" />
            <span v-if="item.liveStatus !== statusList.wait" class="live-status">{{
              item.liveStatus | formatStatus(that)
            }}</span>
            <span v-if="isLiving(item)" class="living-icon"></span>
          </div>
        </div>
      </div>
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>
    <live-room class="mobile-hide" :src="liveItem && liveItem.url"></live-room>
    <div class="enter-liveroom mobile-show">
      <div class="title">{{ liveItem.title || '' }}</div>
      <img @click="openLiveRoom" :src="enterLiveRoomPicUrl" width="100%" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import liveRoom from './live-room.vue';
import Swiper from '~/../../../vendor/assets/javascripts/swiper-bundle.min';
import '~/../../../vendor/assets/stylesheets/swiper-bundle.min.css';
export default {
  components: { liveRoom },
  props: {
    getLiveListUrl: {
      type: String,
      default: '',
    },
    getLiveStatusUrl: {
      type: String,
      default: '',
    },
    enterLiveRoomPicUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    liveItem: {
      get: function () {
        if (this.currentItem) return this.currentItem;
        const liveList = this.liveList.slice(0);
        if (!liveList.length) return '';
        const liveItemIndex = this.findLiveItemIndex(liveList, this.statusList.live);
        const waitItemIndex = this.findLiveItemIndex(liveList, this.statusList.wait);
        const endItemList = this.findLastEndItemList(
          liveList,
          this.statusList.end,
          this.statusList.record,
        );
        // 存在直播中的，播放最近一场直播
        if (liveItemIndex !== -1) {
          return liveList[liveItemIndex];
        }
        // 如果存在未开播的，播放最近一场未开播的
        if (waitItemIndex !== -1) {
          return liveList[waitItemIndex];
        }
        // 否则如果存在已结束的，播放最后一场
        if (!endItemList.length) return '';
        return endItemList.pop();
      },
    },
  },
  created() {
    this.getLiveList();
    this.queryListTimer();
  },
  data() {
    return {
      that: this,
      liveList: [
        // { "title": "直播间111", "url": "https://www.qq.com", "liveStatus": 3, "image": "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3387448728,164884147&fm=26&gp=0.jpg", "live_id": "a" },
        // { "title": "2222", "url": "https://www.hao123.com", "liveStatus": 3, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "b" },
        // { "title": "3333", "url": "https://www.baidu.com", "liveStatus": 3, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "c" },
        // { "title": "44444", "url": "https://www.taobao.com", "liveStatus": 2, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "d" },
        // { "title": "555555", "url": "http://test-live.csdn.net/room-iframe/tangyang8940/R7tIpZ2s", "liveStatus": 1, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "e" },
        // { "title": "66666", "url": "https://www.yy.com", "liveStatus": 2, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "f" },
        // { "title": "777777", "url": "https://www.bilibili.com/", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "g" },
        // { "title": "888888", "url": "https://www.zhihu.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1735300731,83723593&fm=26&gp=0.jpg", "live_id": "h" },
        // { "title": "999999", "url": "https://www.4399.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "i" },
        // { "title": "而非个人个", "url": "https://www.iqiyi.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "j" },
        // { "title": "一口价欲哭", "url": "https://www.jd.com", "liveStatus": 0, "image": "https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1602036846,2392289471&fm=26&gp=0.jpg", "live_id": "k" },
        // { "title": "二爷任天野", "url": "https://www.163.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "l" },
        // { "title": "67位Yui", "url": "https://www.people.com.cn", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "m" },
        // { "title": "3(⊙o⊙)…4有36有5", "url": "https://www.58.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "n" },
        // { "title": "2345245354", "url": "https://www.icbc.com.cn", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "o" },
        // { "title": "苦于苦苦", "url": "https://www.alipay.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "p" },
        // { "title": "而非隔热我跟她热天涯热土好然后", "url": "https://www.dangdang.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "q" },
        // { "title": "有图监狱兔及已提交", "url": "https://www.tuniu.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "r" },
        // { "title": "如图结合体融钰集团软硬件", "url": "https://live.csdn.net/room-iframe/v7xlkz07j8qj/XxCYEFWD", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "s" },
        // { "title": "同一款已退款", "url": "https://www.lagou.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "t" },
        // { "title": "儿童椅让他以后", "url": "https://www.liepin.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "u" },
        // { "title": "如何突然也姬天语", "url": "https://www.zhipin.com", "liveStatus": 0, "image": "https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=3656462586,2475734042&fm=26&gp=0.jpg", "live_id": "v" },
      ],
      statusList: {
        live: 1, // 直播中
        end: 2, // 结束
        record: 3, // 回放
        wait: 0, // 未开播
      },
      liveBarSwiper: '',
      currentItem: '',
      time: 10000,
      timeObj: '',
    };
  },
  methods: {
    async getLiveList() {
      const {
        data: {
          data: { lives: res },
        },
      } = await axios.get(this.getLiveListUrl);
      await this.getLiveStatus(res);
      this.createSwiper();
    },
    async getLiveStatus(liveList) {
      console.log('--------');
      if (!liveList || !liveList.length) return;
      const idList = liveList.map((item) => item.live_id);
      const {
        data: { data: res },
      } = await axios.post(this.getLiveStatusUrl, {
        live_ids: idList,
      });
      liveList.forEach((liveItem) => {
        liveItem.liveStatus = res[liveItem.live_id] || this.statusList.wait;
      });
      this.liveList = liveList;
    },
    queryListTimer() {
      this.timeObj = setInterval(() => {
        this.getLiveStatus(this.liveList);
      }, this.time);
    },
    findLiveItemIndex(list, status) {
      return list.findIndex((item) => item.liveStatus === status);
    },
    findLastEndItemList(list, status1, status2) {
      return list.filter((item) => {
        return item.liveStatus === status1 || item.liveStatus === status2;
      });
    },
    changeLiveItem(item) {
      this.currentItem = item;
    },
    isLiving(item) {
      return item.url === this.liveItem.url;
    },
    createSwiper() {
      const liveBarSwiper = new Swiper('.swiper-container', {
        loop: false, // 循环模式选项
        autoplay: false,
        grabCursor: true,
        slidesPerView: 'auto',
        freeMode: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // watchOverflow: true,
        // 如果需要分页器
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        // },
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: '.swiper-button-next',
        //   prevEl: '.swiper-button-prev',
        // }
      });
      this.slideToCurrentItem(liveBarSwiper);
    },
    openLiveRoom() {
      location.href = this.liveItem.wap_url;
    },
    slideToCurrentItem(liveBarSwiper) {
      const liveList = this.liveList.slice(0);
      const liveItemIndex = this.findLiveItemIndex(liveList, this.statusList.live);
      if (!liveList.length || liveItemIndex === -1) return '';
      this.$nextTick(() => {
        liveBarSwiper.slideTo(liveItemIndex);
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timeObj);
  },
  filters: {
    formatStatus(val, that) {
      const statusList = that.statusList;
      switch (val) {
        case statusList.live:
          return '直播中';
          break;
        case statusList.end:
          return '已结束';
          break;
        case statusList.record:
          return '回放';
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.living-icon {
  width: 88px;
  height: 88px;
  position: absolute;
  bottom: -56px;
  left: 50%;
  transform: translateX(-50%);
  background: url('https://gitcode.net/gitcode/operation-work/uploads/26d65dfc1fb50db7dfec779641b7a15b/living.svg')
    center/contain no-repeat;
}
</style>
